import React from 'react';
import { Link } from 'react-router-dom';

function NavManager(){

  return(
          <div>
            <hr className="sidebar-divider"/>
            <div className="sidebar-heading">
              Manager
            </div>
            <li className="nav-item">
              <Link className="nav-link" to="/users"><i className="fas fa-fw fa-users"></i>Users</Link> 
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/tags"><i className="fas fa-fw fa-tags"></i>Tags</Link> 
            </li>
            <li className="nav-item">
                <Link className="nav-link" to="/categories"><i className="fas fa-fw fa-archive"></i>Categories</Link> 
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/products"><i className="fas fa-fw fa-cubes"></i>Products</Link> 
            </li>
          </div>
  )
}

export default NavManager;