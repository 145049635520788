import React from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';
import '../config.js';
import { theme, createFormData } from '../services/helper.js';
import { alertService } from '../services/alert.service.js';

function BannerList(props) {
  let statusClass = "text-gray-500";
  let statusLabel = "In-Active";
  if(props.info.active && props.info.active != 0){
    statusClass = "";
    statusLabel = "Active";
  }
  let editButton = <Link to={"#"} className={'btn btn-icon-split disabled btn-'+theme()}>
                      <span className="icon text-white-50">
                        <i className="fas fa-edit"></i>
                      </span>
                      <span className="text">Edit</span>
                  </Link>;
  if(parseInt(localStorage.getItem("role")) >= 5){
    editButton = <Link to={"/banner/"+props.info.banner_id} className={'btn btn-icon-split btn-'+theme()}>
                    <span className="icon text-white-50">
                      <i className="fas fa-edit"></i>
                    </span>
                    <span className="text">Edit</span>
                  </Link>;
  }
  return(
        <tr className={statusClass}>
          <td><img src={global.config.asset_server + props.info.document.uri} width="auto" height="100px"/></td>
          <td>{props.info.heading}</td>
          <td>{props.info.ranking}</td>
          <td>{statusLabel}</td>
          <td>{editButton}</td>
        </tr>
  )
}

export default BannerList;