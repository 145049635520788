import React, {useState, useEffect, useRef} from 'react';
import axios from 'axios';
import '../config.js';

import { theme, createFormData } from '../services/helper.js';
import { alertService } from '../services/alert.service.js';
import AttributeList from './attributeList.js';
import Progress from './progress.js';
import { Paginator } from './paginator.js';

function Attributes(props) {
  const [loading, setLoading] = useState(false);
  const [offset, setOffset] = useState(0);
  const [search, setSearch] = useState('');
  const [filteredAttributes, setFilteredAttributes] = useState(props.item.attributes);
  const [attributes, setAttributes] = useState(props.item.attributes);
  const [label, setLabel] = useState('');


  let searchForm = useRef();

  const handleSearch = (event) => {
    const target = event.target;
    const value = target.value;
    const filtered = [];
    for(let i = 0;i<attributes.length;i++){
      if(value === undefined || value.trim() === '' || attributes[i].label.indexOf(value) >= 0){
        filtered.push(attributes[i]);
      }
    }
    setFilteredAttributes(filtered);
  }

  const handleSubmit = (event) =>{
    event.preventDefault();
    if(label){
      axios.post(global.config.api_server+'attribute',createFormData({
        label: label,
        value: '',
        item: props.id,
        task:"create",
        token: localStorage.getItem("token")
      })).then(res => {
        if(res.status === 200 && res.data.status === "success"){
          alertService.success('New attribute created');
          setTimeout(function(){
            window.location.reload();
          }, 1000);
        }else{
          alertService.error(res.data.error);
        }
      }).catch(err => {
        alertService.error(err);
      });
    }else{
      alertService.error('All the fields are required');
    }
  }

  const resetSearch = (event) => {
    searchForm.current.reset();
    setSearch('');
    setFilteredAttributes(attributes);
  }

  const onPageChange = (index_start) => {
    setOffset(index_start);
  }

  let createAttribute = "";
  if(parseInt(localStorage.getItem("role")) >= 3){
    createAttribute = <a href="#" className={'btn btn-icon-split float-right btn-'+theme()} data-toggle="modal" data-target="#createAttributeModal">
                  <span className="icon text-white-50">
                    <i className="fas fa-bolt"></i>
                  </span>
                  <span className="text">Add New Attribute</span>
                </a>;
  }
  let total = 0;
  let counter = 0;
  let offsetCounter = 0;
  const paginated_attributes = [];
  for (const u of filteredAttributes) {
    total++;
    offsetCounter++;
    if (offsetCounter > offset && counter < global.config.items_per_page){
      counter++;
      paginated_attributes.push(u);
    }
  }
  return(
    <div>
      <div className="card shadow mb-4">
        <div className="card-header py-3">
          {createAttribute}
          <form ref={searchForm} className="form-inline">
            <h6 className={'form-group mr-3 font-weight-bold text-'+theme()}>Attribute(s)</h6>
            <div className="form-group mr-3">
                <input type="search" label="search" className="form-control" placeholder="Search" onChange={handleSearch}/>
              </div>
              {search &&
              <div className="form-group mr-3">
                <a href="#" className="btn btn-default btn-icon-split" onClick={resetSearch}>
                  <span className="icon">
                    <i className="fas fa-times"></i>
                  </span>
                </a>
              </div>
              }
          </form>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <div className="row">
            {loading === true ? (
              <Progress label='Loading..' small={true}/>
            ):null}
              <div className="col-sm-12">
                <table className="table table-bordered" width="100%">
                  <thead>
                    <tr>
                      <th>Label</th>
                      <th width="60%">Value</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {paginated_attributes.map(attr =>
                      <AttributeList info={attr} key={attr.attribute_id}/>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <Paginator
                itemCount={total}
                itemsPerPage={global.config.items_per_page}
                showNextPages={2}
                showPrevPages={2}
                filterTotal={filteredAttributes.length}
                grandTotal={attributes.length}
                onClick={onPageChange.bind(this)}
              />
          </div>
        </div>
      </div>
      <div className="modal fade" id="createAttributeModal" tabIndex="-1" role="dialog" aria-labelledby="create-popup" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="create-popup">Add new document</h5>
              <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <form>
              <div className="modal-body">
                <div className="form-group">
                  <label>Label</label>
                  <input type="text" value={label} maxLength="50" name="label" className="form-control" id="label" placeholder="Enter Label" onChange={(e) => setLabel(e.target.value)}/>
                </div>
              </div>
              <div className="modal-footer">
                <button className="btn btn-default" type="reset" data-dismiss="modal">Close</button>
                <button className={'btn btn-'+theme()} type="button" onClick={handleSubmit}>Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Attributes;