import React, {useState, useEffect, useRef} from 'react';
import axios from 'axios';
import '../config.js';

import { theme, createFormData } from '../services/helper.js';
import { alertService } from '../services/alert.service.js';
import DocumentList from '../components/documentList.js';
import Progress from '../components/progress.js';
import { Paginator } from '../components/paginator.js';

function SingleDocument(props) {
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [offset, setOffset] = useState(0);
  const [document, setDocument] = useState(props.item.document);
  const [file, setFile] = useState(null);
  const [label, setLabel] = useState('');
  const [docType, setDocType] = useState('Image');

  let searchForm = useRef();

  const handleSubmit = (event) =>{
    event.preventDefault();
    if(label && file && docType){
      const formData = new FormData;
      formData.append('file', file);
      formData.append('file_name', file.name);
      formData.append('label', label);
      formData.append('task', "upload");
      formData.append('item', props.id);
      formData.append('item_type', props.type);
      formData.append('type', docType);
      formData.append('token', localStorage.getItem("token"));
      axios.post(global.config.api_server+'document',formData).then(res => {
        if(res.status === 200 && res.data.status === "success"){
          alertService.success('Document uploaded');
          setTimeout(function(){
            window.location.reload();
          }, 1000);
        }else{
          alertService.error(res.data.error);
        }
      }).catch(err => {
        alertService.error(err);
      });
    }else{
      alertService.error('All the fields are required');
    }
  }

  let uploadDocument = "";
  if(parseInt(localStorage.getItem("role")) >= 3 && document == undefined){
    uploadDocument = <a href="#" className={'btn btn-icon-split float-right btn-'+theme()} data-toggle="modal" data-target="#createDocumentModal">
                  <span className="icon text-white-50">
                    <i className="fas fa-file"></i>
                  </span>
                  <span className="text">Add Document</span>
                </a>;
  }
  const docTypes = global.config.docType.split(",");
  return(
    <div>
      <div className="card shadow mb-4">
        <div className="card-header py-3">
          {uploadDocument}
          <form ref={searchForm} className="form-inline">
              <h6 className={'form-group mr-3 font-weight-bold text-'+theme()}>Document</h6>
            </form>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <div className="row">
            {loading === true ? (
              <Progress label='Loading..' small={true}/>
            ):null}
              <div className="col-sm-12">
                <table className="table table-bordered" width="100%">
                  <thead>
                    <tr>
                      <th></th>
                      <th>Label</th>
                      <th>Type</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {document != undefined? (
                      <DocumentList info={document} key={document.document_id}/>
                    ):null}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade" id="createDocumentModal" tabIndex="-1" role="dialog" aria-labelledby="create-popup" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="create-popup">Add new document</h5>
              <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <form>
              <div className="modal-body">
                <div className="form-group">
                  <label>Label</label>
                  <input type="text" value={label} maxLength="50" name="label" className="form-control" id="label" placeholder="Enter Label" onChange={(e) => setLabel(e.target.value)}/>
                </div>
                <div className="form-group">
                  <label>Document Type</label>
                  <select name="filter" className="form-control" onChange={(e) => setDocType(e.target.value)}>
                  {docTypes.map(d =>
                    <option value={d} key={d}>{d}</option>
                  )}
                  </select>
                </div>
                <div className="form-group">
                  <label>File</label>
                  <input type="file" maxLength="50" name="file" className="form-control" id="file" placeholder="Upload File" onChange={(e) => setFile(e.target.files[0])}/>
                </div>
              </div>
              <div className="modal-footer">
                <button className="btn btn-default" type="reset" data-dismiss="modal">Close</button>
                <button className={'btn btn-'+theme()} type="button" onClick={handleSubmit}>Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SingleDocument;