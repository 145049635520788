import React from 'react';
import { Link } from 'react-router-dom';

function NavAdmin() {

    return(
            <div>
              <hr className="sidebar-divider"/>
              <div className="sidebar-heading">
                Super Administrator
              </div>
              <li className="nav-item">
                <Link className="nav-link" to="/users"><i className="fas fa-fw fa-users"></i>Users</Link> 
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/users/client"><i className="fas fa-fw fa-users"></i>Clients</Link> 
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/tags"><i className="fas fa-fw fa-tags"></i>Tags</Link> 
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/categories"><i className="fas fa-fw fa-archive"></i>Categories</Link> 
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/products"><i className="fas fa-fw fa-cubes"></i>Products</Link> 
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/banners"><i className="fas fa-fw fa-images"></i>Banner</Link> 
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/settings"><i className="fas fa-fw fa-cogs"></i>Settings</Link> 
              </li>
            </div>
    )
}

export default NavAdmin;