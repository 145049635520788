import React, {useState, useEffect, useRef} from 'react';
import axios from 'axios';
import '../config.js';

import { alertService } from '../services/alert.service.js';
import TagList from '../components/tagList.js';
import { Paginator } from '../components/paginator.js';
import { theme, createFormData } from '../services/helper.js';
import Progress from '../components/progress.js';

function Tags() {
  const [search, setSearch] = useState('');
  const [tags, setTags] = useState({});
  const [filteredTags, setFilteredTags] = useState([]);
  const [offset, setOffset] = useState(0);
  const [loading, setLoading] = useState(true);
  const [label, setLabel] = useState('');
  const [identifier, setIdentifier] = useState('');
  const [filter, setFilter] = useState('None');

  let searchForm = useRef();

  useEffect(()=>{
    axios.get(global.config.api_server+'tags?token='+localStorage.getItem("token"))
    .then(res => {
      if(res.status === 200 && res.data.status === "success"){
        const temp = res.data.tags;
        setTags(temp)
        setFilteredTags(temp);
        setLoading(false);
      }
    }).catch(err => {
      alertService.error(err);
    });
  }, []);
  
  const onPageChange = (index_start) => {
    setOffset(index_start);
  }
  
  const handleSearch = (event) => {
    const target = event.target;
    const value = target.value;
    const filtered = [];
    for(let i = 0;i<tags.length;i++){
      if(value === undefined || value.trim() === '' || tags[i].label.indexOf(value) >= 0 || tags[i].identifier.indexOf(value) >= 0 || tags[i].filter.indexOf(value) >= 0){
        filtered.push(tags[i]);
      }
    }
    setFilteredTags(filtered);
  }

  const handleSubmit = (event) =>{
    event.preventDefault();
    if(label && identifier && filter){
      axios.post(global.config.api_server+'tag',createFormData({
        label: label,
        identifier: identifier,
        filter: filter,
        task:"create",
        token: localStorage.getItem("token")
      })).then(res => {
        if(res.status === 200 && res.data.status === "success"){
          alertService.success('New tag created');
          setTimeout(function(){
            window.location.reload();
          }, 1000);
        }else{
          alertService.error(res.data.error);
        }
      }).catch(err => {
        alertService.error(err);
      });
    }else{
      alertService.error('All the fields are required');
    }
  }

  const resetSearch = (event) => {
    searchForm.current.reset();
    setSearch('');
    setFilteredTags(tags);
  }

  const updateLabel = (value) => {
    setLabel(value);
    value = value.toLowerCase().replaceAll(" ","-");
    setIdentifier(value);
  }

  let createTag = "";
  if(parseInt(localStorage.getItem("role")) >= 5){
    createTag = <a href="#" className={'btn btn-icon-split float-right btn-'+theme()} data-toggle="modal" data-target="#createModal">
                  <span className="icon text-white-50">
                    <i className="fas fa-tag"></i>
                  </span>
                  <span className="text">Create New Tag</span>
                </a>;
  }
  let total = 0;
  let counter = 0;
  let offsetCounter = 0;
  const paginated_tags = [];
  for (const u of filteredTags) {
    total++;
    offsetCounter++;
    if (offsetCounter > offset && counter < global.config.items_per_page){
      counter++;
      paginated_tags.push(u);
    }
  }
  const filterTypes = global.config.filters.split(",");
  if(loading){
    return <Progress/>;
  }else{
    return(
      <div className="container-fluid">
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            {createTag}
            <form ref={searchForm} className="form-inline">
                <h6 className={'form-group mr-3 font-weight-bold text-'+theme()}>Tags</h6>
                <div className="form-group mr-3">
                  <input type="search" name="search" className="form-control" placeholder="Search" onChange={handleSearch}/>
                </div>
                {search &&
                <div className="form-group mr-3">
                  <a href="#" className="btn btn-default btn-icon-split" onClick={resetSearch}>
                    <span className="icon">
                      <i className="fas fa-times"></i>
                    </span>
                  </a>
                </div>
                }
              </form>
          </div>
          <div className="card-body">
            <div className="table-responsive">
              <div className="row">
                <div className="col-sm-12">
                  <table className="table table-bordered" width="100%">
                    <thead>
                      <tr>
                        <th>Identifier</th>
                        <th>Label</th>
                        <th>Filter Type</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {paginated_tags.map(tag =>
                        <TagList info={tag} key={tag.tag_id}/>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              <Paginator
                  itemCount={total}
                  itemsPerPage={global.config.items_per_page}
                  showNextPages={2}
                  showPrevPages={2}
                  filterTotal={filteredTags.length}
                  grandTotal={tags.length}
                  onClick={onPageChange.bind(this)}
                />
            </div>
          </div>
        </div>
        <div className="modal fade" id="createModal" tabIndex="-1" role="dialog" aria-labelledby="create-popup" aria-hidden="true">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="create-popup">Create New Tag</h5>
                  <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <form>
                  <div className="modal-body">
                    <div className="form-group">
                      <label>Label</label>
                      <input type="text" value={label} maxLength="50" name="label" className="form-control" id="label" placeholder="Enter Label" onChange={(e) => updateLabel(e.target.value)}/>
                    </div>
                    <div className="form-group">
                      <label>Identifier</label>
                      <input type="text" value={identifier} maxLength="50" name="identifier" className="form-control" id="identifier" placeholder="Enter Identifier" onChange={(e) => setIdentifier(e.target.value)}/>
                    </div>
                    <div className="form-group">
                      <label>Filter Type</label>
                      <select name="filter" className="form-control" onChange={(e) => setFilter(e.target.value)}>
                      {filterTypes.map(f =>
                        <option value={f} key={f}>{f}</option>
                      )}
                      </select>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button className="btn btn-default" type="reset" data-dismiss="modal">Close</button>
                    <button className={'btn btn-'+theme()} type="button" onClick={handleSubmit}>Submit</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
      </div>
    )
  }
}

export default Tags;