import React, {Component} from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import axios from 'axios';
import Dashboard from './dashboard.js';
import Profile from './profile.js';
import Contact from './contact.js';
import Users from './users.js';
import User from './user.js';
import Tags from './tags.js';
import Tag from './tag.js';
import Categories from './categories.js';
import Category from './category.js';
import Products from './products.js';
import Product from './product.js';
import Banners from './banners.js';
import Settings from './settings.js';
import Banner from './banner.js';
import Topnav from '../components/topnav.js';
import Footer from '../components/footer.js';

import { theme } from '../services/helper.js';
import { alertService } from '../services/alert.service.js';
import {Alert} from '../components/alert.js';

import NavSuperAdmin from '../components/navigation/superAdmin.js';
import NavAdmin from '../components/navigation/admin.js';
import NavManager from '../components/navigation/manager.js';


class Base extends Component {
  
  componentDidMount(){
    const currentTheme = localStorage.getItem('theme');
    axios.get(global.config.api_server+'login?token='+localStorage.getItem("token"))
    .then(res => {
      let status = false;
      if(res.status == 200 && res.data.status === "success" && res.data.user){
        localStorage.setItem('name', res.data.user.name);
        localStorage.setItem('email', res.data.user.email);
        localStorage.setItem('mobile', res.data.user.mobile);
        localStorage.setItem('role', res.data.user.role);
        localStorage.setItem('user_id', res.data.user.user_id);
        localStorage.setItem('supervisor', res.data.user.supervisor);
        localStorage.setItem('theme', res.data.user.theme);
        status = true;
      }else if(res.data.error !== undefined){
        alertService.error(res.data.error);
      }else{
        alertService.error("Unknown Error");
      }
      if(!status){
        localStorage.clear();
        localStorage.setItem('theme', currentTheme);
        setTimeout(
          () => window.location.reload(), 
          2000
        );
      }
    }).catch(error => {
      alertService.error("Network error");
      localStorage.clear();
      localStorage.setItem('theme', currentTheme);
    });
  }
  
  render() {
    let navigation;
    const currentRole = localStorage.getItem("role");
    if(currentRole == 10){
      navigation =  <NavSuperAdmin/>
    }else if(currentRole == 5){
      navigation =  <NavAdmin/>
    }else if(currentRole == 4){
      navigation =  <NavManager/>
    }
    
    return(
        <BrowserRouter>
          <ul className={'navbar-nav sidebar sidebar-dark accordion bg-gradient-'+theme()} id="accordionSidebar">
            <a className="sidebar-brand d-flex align-items-center justify-content-center" href="/">
              <div className="sidebar-brand-icon rotate-n-15">
                <i className="fas fa-clipboard"></i>
              </div>
              <div className="sidebar-brand-text mx-3">Clipboard</div>
            </a>
            <hr className="sidebar-divider my-0"/>
            <li className="nav-item">
              <a className="nav-link" to="/"><i className="fas fa-fw fa-tachometer-alt"></i>Dashboard</a> 
            </li>
            {navigation}
            
            <hr className="sidebar-divider"/>
            <div className="sidebar-heading">
              CONNECT
            </div>
            <li className="nav-item">
            <a className="nav-link" to="/contact"><i className="fas fa-fw fa-address-card"></i>Contact Us</a> 
            </li>
        
            <hr className="sidebar-divider d-none d-md-block"/>
        
            <div className="text-center d-none d-md-inline">
              <button className="rounded-circle border-0" id="sidebarToggle"></button>
            </div>
        
          </ul>
          <Alert />
          <div id="content-wrapper" className="d-flex flex-column">
            <div id="content">
              <Topnav/>
                <Routes>
                  <Route path="*" element={<Dashboard />} />
                  <Route index path='/' element={ <Dashboard /> } ></Route>
                  <Route exact path='/profile' element={ <Profile /> } ></Route>
                  <Route exact path='/profile/:subnav' element={ <Profile /> } ></Route>
                  <Route exact path='/profile' element={ <Profile /> } ></Route>
                  <Route exact path='/profile' element={ <Profile /> } ></Route>
                  <Route exact path='/contact' element={ <Contact /> } ></Route>
                  <Route exact path='/users' element={ <Users /> } ></Route>
                  <Route exact path='/users/:subnav' element={ <Users /> } ></Route>
                  <Route exact path='/user/:id' element={ <User /> } ></Route>
                  <Route exact path='/user/:id/:subnav' element={ <User /> } ></Route>
                  <Route exact path='/tags' element={ <Tags /> } ></Route>
                  <Route exact path='/tag/:id' element={ <Tag /> } ></Route>
                  <Route exact path='/categories' element={ <Categories /> } ></Route>
                  <Route exact path='/category/:id' element={ <Category /> } ></Route>
                  <Route exact path='/products' element={ <Products /> } ></Route>
                  <Route exact path='/product/:id' element={ <Product /> } ></Route>
                  <Route exact path='/banners' element={ <Banners /> } ></Route>
                  <Route exact path='/banner/:id' element={ <Banner /> } ></Route>
                  <Route exact path='/settings' element={ <Settings /> } ></Route>
                </Routes>
            </div>
            <Footer/>
          </div>
        </BrowserRouter>
    );
  }
}

export default Base;