import React, {useState, useEffect, useRef} from 'react';
import axios from 'axios';
import '../config.js';

import { alertService } from '../services/alert.service.js';
import BannerList from '../components/bannerList.js';
import { Paginator } from '../components/paginator.js';
import { theme, createFormData } from '../services/helper.js';
import Progress from '../components/progress.js';

function Banners() {
  const [banners, setBanners] = useState([]);
  const [loading, setLoading] = useState(true);
  const [heading, setHeading] = useState('');

  useEffect(()=>{
    axios.get(global.config.api_server+'banners?token='+localStorage.getItem("token"))
    .then(res => {
      if(res.status === 200 && res.data.status === "success"){
        const temp = res.data.banners;
        setBanners(temp);
        setLoading(false);
      }
    }).catch(err => {
      alertService.error(err);
    });
  }, []);

  const handleSubmit = (event) =>{
    event.preventDefault();
    if(heading){
      axios.post(global.config.api_server+'banner',createFormData({
        heading: heading,
        task:"create",
        token: localStorage.getItem("token")
      })).then(res => {
        if(res.status === 200 && res.data.status === "success"){
          alertService.success('New banner created');
          setTimeout(function(){
            window.location.reload();
          }, 1000);
        }else{
          alertService.error(res.data.error);
        }
      }).catch(err => {
        alertService.error(err);
      });
    }else{
      alertService.error('All the fields are required');
    }
  }

  let createBanner = "";
  if(parseInt(localStorage.getItem("role")) >= 5){
    createBanner = <a href="#" className={'btn btn-icon-split float-right btn-'+theme()} data-toggle="modal" data-target="#createModal">
                  <span className="icon text-white-50">
                    <i className="fas fa-image"></i>
                  </span>
                  <span className="text">Create New Banner</span>
                </a>;
  }

  if(loading){
    return <Progress/>;
  }else{
    return(
      <div className="container-fluid">
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            {createBanner}
            <h6 className={'form-group mr-3 font-weight-bold text-'+theme()}>Banners</h6>
          </div>
          <div className="card-body">
            <div className="table-responsive">
              <div className="row">
                <div className="col-sm-12">
                  <table className="table table-bordered" width="100%">
                    <thead>
                      <tr>
                        <th>Image</th>
                        <th>Heading</th>
                        <th>Ranking</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {banners.map(banner =>
                        <BannerList info={banner} key={banner.banner_id}/>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade" id="createModal" tabIndex="-1" role="dialog" aria-labelledby="create-popup" aria-hidden="true">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="create-popup">Create New Banner</h5>
                  <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <form>
                  <div className="modal-body">
                    <div className="form-group">
                      <label>Heading</label>
                      <input type="text" value={heading} maxLength="100" name="heading" className="form-control" id="heading" placeholder="Enter Heading" onChange={(e) => setHeading(e.target.value)}/>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button className="btn btn-default" type="reset" data-dismiss="modal">Close</button>
                    <button className={'btn btn-'+theme()} type="button" onClick={handleSubmit}>Submit</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
      </div>
    )
  }
}

export default Banners;