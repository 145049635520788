export function theme(){
  let num = 0;
  if(localStorage.getItem("theme") !== undefined && localStorage.getItem("theme") !== null){
    num = parseInt(localStorage.getItem("theme"));
  }
  if(num === 1){
    return 'success';
  }else if(num === 2){
    return 'info';
  }else if(num === 3){
    return 'warning';
  }else if(num === 4){
    return 'danger';
  }else if(num === 5){
    return 'secondary';
  }else if(num === 6){
    return 'dark';
  }else{
    return 'primary';
  }
}

export function createFormData(object) {
  let formData = '';
  Object.keys(object).forEach(key => formData += key+'='+encodeURI(object[key])+'&');
  return formData.substring(0,formData.length-1);
}

export function createFormData2(object) {
  const formData = new FormData();
  Object.keys(object).forEach(key => formData.append(key, object[key]));
  return formData;
}