import React, {useState, useEffect, useRef} from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';
import '../config.js';
import { theme, createFormData } from '../services/helper.js';
import { alertService } from '../services/alert.service.js';
import RichTextEditor from '../components/rte.js';

function AttributeList(props) {

  const [value, setValue] = useState(props.info.value);

  const handleDelete = (event) => {
    event.preventDefault();
    if(props.info.attribute_id){
      axios.post(global.config.api_server+'attribute',createFormData({
        id: props.info.attribute_id,
        task:"delete",
        token: localStorage.getItem("token")
      })).then(res => {
        if(res.status === 200 && res.data.status === "success"){
          alertService.success('Attribute Deleted');
          setTimeout(function(){
            window.location.reload();
          }, 1000);
        }else{
          alertService.error(res.data.error);
        }
      }).catch(err => {
        alertService.error(err);
      });
    }else{
      alertService.error('Error deleting attribute');
    }
  }

  const handleUpdate = (event) => {
    event.preventDefault();
    if(props.info.attribute_id && value){
      axios.post(global.config.api_server+'attribute',createFormData({
        value: value,
        id: props.info.attribute_id,
        task:"update",
        token: localStorage.getItem("token")
      })).then(res => {
        if(res.status === 200 && res.data.status === "success"){
          alertService.success('Attribute updated');
        }else{
          alertService.error(res.data.error);
        }
      }).catch(err => {
        alertService.error(err);
      });
    }else{
      alertService.error('Error updating attribute');
    }
  }

  let action = <span>
                    <button className={'btn btn-icon-split disabled mr-2 btn-'+theme()}>
                      <span className="icon text-white-50">
                        <i className="fas fa-save"></i>
                      </span>
                      <span className="text">Save</span>
                    </button>
                    <button className={'btn btn-icon-split disabled btn-'+theme()}>
                      <span className="icon text-white-50">
                        <i className="fas fa-trash"></i>
                      </span>
                      <span className="text">Delete</span>
                    </button></span>;
  if(parseInt(localStorage.getItem("role")) >= 3){
    action = <span>
              <button onClick={handleUpdate} className={'btn btn-icon-split mr-2 btn-'+theme()}>
                <span className="icon text-white-50">
                  <i className="fas fa-save"></i>
                </span>
                <span className="text">Save</span>
              </button>
              <button onClick={handleDelete} className={'btn btn-icon-split btn-'+theme()}>
                <span className="icon text-white-50">
                  <i className="fas fa-trash"></i>
                </span>
                <span className="text">Delete</span>
              </button>
            </span>;
  }
  return(
        <tr>
          <td>{props.info.label}</td>
          <td><RichTextEditor id={'attribute-'+props.info.attribute_id} label={'Attribute - ' + props.info.label} value={props.info.value} handleSubmit={(e)=>setValue(e)}/></td>
          <td>{action}</td>
        </tr>
  )
}

export default AttributeList;