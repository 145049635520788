import React, {Component} from 'react';

import { theme } from '../services/helper.js';

class Progress extends Component {
  
  constructor(props) {
    super(props);
  }
  
  componentDidMount(){

  }

  render() {
    let smallClass = "";
    if(this.props.small){
      smallClass = "progress-sm";
    }
    return(
      <div className="container-fluid">
        <h6 className={'mb-2 text-'+theme()}>{this.props.label || 'Loading'}...</h6>
        <div className={'progress mb-3 '+smallClass}>
          <div className={'progress-bar progress-bar-striped progress-bar-animated bg-'+theme()} role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
      </div>
    )
  }
}

export default Progress;