import React, {useState, useEffect, useRef} from 'react';
import { theme } from '../services/helper.js';

function Dasboard() {

  return(
    <div class="container-fluid">
      <div class="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 class="h3 mb-0 text-gray-800">Dashboard</h1>
        <a href="#" className={"d-none d-sm-inline-block btn btn-sm shadow-sm btn-"+theme()}><i class="fas fa-download fa-sm text-white-50"></i> Generate Report</a>
      </div>
    </div>
  )
}

export default Dasboard;