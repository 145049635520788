import React, {useState, useEffect, useRef} from 'react';

function Contact(){
  return(
    <div>
      <h1>Contact</h1>
    </div>
  )
}

export default Contact;