import React, {useState, useEffect, useRef} from 'react';
import { useParams } from 'react-router';
import {Link} from 'react-router-dom';
import axios from 'axios';
import '../config.js';

import { theme, formData } from '../services/helper.js';
import { alertService } from '../services/alert.service.js';
import UserProfile from '../components/userProfile.js';
import AdditionalProfile from '../components/additionalProfile.js';
import Progress from '../components/progress.js';

function Profile(props) {
  const { id, subnav } = useParams();
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(()=>{
    axios.get(global.config.api_server+'user?token='+localStorage.getItem("token")) 
    .then(res => {
      if(res.status === 200 && res.data.status === "success"){
        const currentUser = res.data.user;
        if(currentUser === null || currentUser === undefined){
          alertService.error("Profile not found");
        }else{
          setUser(currentUser);
          setLoading(false);
        }
      }else{
        alertService.error(res.data.error);
      }
    }).catch(err => {
      alertService.error(err);
    });
  }, []);
  
  
  const checkSubNav = (param) => {
    let design = "";
    if(subnav === param){
      design = " disabled";
    }else if(param === 'basic' && (subnav === null || subnav === undefined)){
      design = " disabled";
    }
    return design;
  }

  let subnavItem = <UserProfile id={id} user={user}></UserProfile>;
  if(subnav === 'additional'){
    subnavItem = <AdditionalProfile id={id} user={user}></AdditionalProfile>;
  }else if(subnav === 'document'){
    subnavItem = "";
  }
  if(loading){
    return <Progress/>;
  }else{
    return(
      <div className="container-fluid">
        <div className="mb-3">
          <Link to={"/profile"} className={'btn btn-icon-split mr-2 btn-'+theme() + checkSubNav('basic')}>
            <span className="icon text-white-50">
              <i className="fas fa-user"></i>
            </span>
            <span className="text">Basic Info</span>
          </Link>
          <Link to={"/profile/additional"} className={'btn btn-icon-split mr-2 btn-'+theme() + checkSubNav('additional')}>
            <span className="icon text-white-50">
              <i className="fas fa-user-shield"></i>
            </span>
            <span className="text">Additional Info</span>
          </Link>
          <Link to={"/profile/document"} className={'btn btn-icon-split mr-2 btn-'+theme() + checkSubNav('experience')}>
            <span className="icon text-white-50">
              <i className="fas fa-folder-open"></i>
            </span>
            <span className="text">Document</span>
          </Link>
        </div>
        
        {subnavItem}
        
      </div>
    )
  }
}

export default Profile;