import React, {useState, useEffect, useRef} from 'react';
import axios from 'axios';
import '../config.js';
import { theme, createFormData } from '../services/helper.js';
import { alertService } from '../services/alert.service.js';
import Progress from '../components/progress.js';
import RichTextEditor from '../components/rte.js';

function ProductInfo(props) {
  const [loading, setLoading] = useState(false);
  const [label, setLabel] = useState(props.product.label);
  const [price, setPrice] = useState(props.product.price);
  const [rank, setRank] = useState(props.product.ranking);
  const [quantity, setQuantity] = useState(props.product.quantity);
  const [mrp, setMrp] = useState(props.product.mrp);
  const [description, setDescription] = useState(props.product.description);
  const [active, setActive] = useState(props.product.active);
  
  const handleSubmit = (event) => {
    event.preventDefault();
    if(label && props.id){
      setLoading(true);
      axios.post(global.config.api_server+'product',createFormData({
        label: label,
        active: active,
        id: props.id,
        ranking: rank,
        price: price,
        mrp: mrp,
        quantity: quantity,
        description: description,
        task: 'edit',
        token: localStorage.getItem("token")
      })).then(res => {
        setLoading(false);
        if(res.status === 200 && res.data.status === "success"){
          alertService.success('Product updated');
        }else{
          alertService.error(res.data.error);
        }
      }).catch(err => {
        alertService.error(err);
        setLoading(false);
      });
    }else{
      alertService.error('Some fields are required');
    }
  }

  return(
    <div className="card shadow mb-4">
      <div className="card-header py-3">            
        <button onClick={handleSubmit} className={'btn btn-icon-split float-right btn-'+theme()}>
          <span className="icon text-white-50">
            <i className="fas fa-save"></i>
          </span>
          <span className="text">Save</span>
        </button>
        <h6 className={'mt-2 font-weight-bold text-'+theme()}>Product Information</h6>
      </div>
      <div className="card-body">
        <div className="row justify-content-center">
          {loading === true ? (
            <Progress label='Saving' small={true}/>
          ):null}
          <div className="col-md-6">
            <div className="form-group">
              <label>Identifier</label>
              <input type="text" value={props.product.identifier} maxLength="50" name="identifier" className="form-control disabled" id="name" placeholder="Enter Identfier" readOnly={true}/>
            </div>
            <div className="form-group">
              <label>Label *</label>
              <input type="text" value={label} maxLength="50" name="label" className="form-control" id="label" placeholder="Enter Label" onChange={(e) => setLabel(e.target.value)}/>
            </div>
            <div className="form-group">
              <label>Price</label>
              <input type="number" value={price} max="999999" name="price" className="form-control" id="price" placeholder="Enter Price" onChange={(e) => setPrice(e.target.value)}/>
            </div>
            <div className="form-group">
              <label>MRP (for showcasing discount)</label>
              <input type="number" value={mrp} max="999999" name="mrp" className="form-control" id="mrp" placeholder="Enter MRP" onChange={(e) => setMrp(e.target.value)}/>
            </div>
            <div className="form-group">
              <label>Quantity available(Set to -1 for unlimited)</label>
              <input type="number" value={quantity} max="999999" name="mrp" className="form-control" id="quantity" placeholder="Enter Quantity" onChange={(e) => setQuantity(e.target.value)}/>
            </div>
            <div className="form-group">
              <label>Rank</label>
              <input type="number" value={rank} max="999999" name="rank" className="form-control" id="rank" placeholder="Enter Rank" onChange={(e) => setRank(e.target.value)}/>
            </div>
            <div className="form-group">
              <label>Status</label>
              <select name="active" className="form-control" value={active} id="active" onChange={(e) => setActive(e.target.value)}>
                <option value="0">In-Active</option>
                <option value="1">Active</option>
              </select>
            </div>
          </div>
          <div className="col-md-6">
            <RichTextEditor id={'description-'+props.product.product_id} label={'Description'} value={props.product.description} handleSubmit={(e)=>setDescription(e)}/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductInfo;