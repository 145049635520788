module.exports = global.config = {
    api_server: "https://clipboard.insploreconsultants.com/v1/",
    asset_server: "https://clipboard.insploreconsultants.com",
    //api_server: "http://localhost/v1/",
    filters: "None,Brand,Size,Build Material",
    placements: "None,Header,Featured",
    docType: "Image,Main,PDF,Document,Download",
    status: "Active,Selected,Rejected,Suspended,Terminated,Warning,Left",
    frequency: ['Once',
      'Weekly',
      'Monthly',
      'Annually'
    ],
    items_per_page: 20,
    settings_token: "seo_page_title,seo_page_description"
};