import React, {useState, useEffect, useRef} from 'react';
import axios from 'axios';
import '../config.js';

import { theme, createFormData } from '../services/helper.js';
import { alertService } from '../services/alert.service.js';
import Progress from '../components/progress.js';

function AdditionalProfile(props) {
  const [loading, setLoading] = useState(false);
  const [themeColor, setTheme] = useState(localStorage.getItem("theme"));
  const [bloodGroup, setBloodGroup] = useState(props.user.blood_group);
  const [father, setFather] = useState(props.user.father);
  const [mother, setMother] = useState(props.user.mother);
  const [emergency, setEmergency] = useState(props.user.emergency);
  const [emergencyMobile, setEmergencyMobile] = useState(props.user.emergency_mobile);
  const [personalEmail, setPersonalEmail] = useState(props.user.personal_email);
  const [personalMobile, setPersonalMobile] = useState(props.user.personal_mobile);
  const [maritalStatus, setMaritalStatus] = useState(props.user.marital_status);
  const [religion, setReligion] = useState(props.user.religion);

  
  const handleSubmit = (event) => {
    event.preventDefault();
    if(father && mother){
      setLoading(true);
      axios.post(global.config.api_server+'user',createFormData({
        theme: themeColor,
        blood_group: bloodGroup,
        father: father,
        mother: mother,
        emergency: emergency,
        emergency_mobile: emergencyMobile,
        personal_email: personalEmail,
        personal_mobile: personalMobile,
        marital_status: maritalStatus,
        religion: religion,
        token: localStorage.getItem("token"),
        task: 'additional_profile'
      })).then(res => {
        setLoading(false);
        if(res.status === 200 && res.data.status === "success"){
          alertService.success('Profile updated');
          localStorage.setItem("theme", themeColor);
          if(themeColor != localStorage.getItem("theme")){
            window.location.reload();
          }
        }else{
          alertService.error(res.data.error);
        }
      }).catch(err => {
        alertService.error(err);
        setLoading(false);
      });
    }else{
      alertService.error('Some fields are required');
    }
  }
  
  return(
    <div className="card shadow mb-4">
      <div className="card-header py-3">            
        <button onClick={(e) => handleSubmit(e)} className={'btn btn-icon-split float-right btn-'+theme()}>
          <span className="icon text-white-50">
            <i className="fas fa-save"></i>
          </span>
          <span className="text">Save</span>
        </button>
        <h6 className={'mt-2 font-weight-bold text-'+theme()}>My Additional Info</h6>
      </div>
      <div className="card-body">
        <div className="row">
          {loading === true ? (
            <Progress label='Saving' small={true}/>
          ):null}
          <div className="col-md-4">
            <div className="form-group">
              <label>Supervisor</label>
              <input type="text" value={props.user.supervisor_name} className="form-control" disabled={true}/>
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <label>Supervisor Mobile</label>
              <input type="text" value={props.user.supervisor_mobile} className="form-control" disabled={true}/>
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <label>Supervisor Email</label>
              <input type="email" value={props.user.supervisor_email} className="form-control" disabled={true}/>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <hr/>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label>Theme</label>
              <select name="theme" className="form-control" value={themeColor} onChange={(e) => setTheme(e.target.value)}>
                <option value="0">Blue</option>
                <option value="1">Green</option>
                <option value="2">Light Blue</option>
                <option value="3">Orange</option>
                <option value="4">Red</option>
                <option value="5">Grey</option>
                <option value="6">Dark</option>
              </select>
            </div>
            <div className="form-group">
              <label>Blood Group</label>
              <input type="text" value={bloodGroup} maxLength="5" name="blood_group" className="form-control" placeholder="Enter Blood Group" onChange={(e) => setBloodGroup(e.target.value)}/>
            </div>
            <div className="form-group">
              <label>Father Name *</label>
              <input type="text" value={father} maxLength="50" name="father" className="form-control" placeholder="Enter Father Name" onChange={(e) => setFather(e.target.value)}/>
            </div>
            <div className="form-group">
              <label>Mother Name *</label>
              <input type="text" value={mother} maxLength="50" name="mother" className="form-control" placeholder="Enter Mother Name" onChange={(e) => setMother(e.target.value)}/>
            </div>
            <div className="form-group">
              <label>Religion</label>
              <select name="religion" className="form-control" value={religion} onChange={(e) => setReligion(e.target.value)}>
                <option>--Select--</option>
                <option value="Hinduism">Hinduism</option>
                <option value="Islam">Islam</option>
                <option value="Christianity">Christianity</option>
                <option value="Sikhism">Sikhism</option>
                <option value="Buddhism">Buddhism</option>
                <option value="Jainism">Jainism</option>
                <option value="Jainism">Jainism</option>
                <option value="Atheism">Atheism</option>
                <option value="Others">Others</option>
              </select>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label>Emegency Contact Person Name</label>
              <input type="text" value={emergency} maxLength="50" name="emergency" className="form-control" placeholder="Enter Emegency Contact Name" onChange={(e) => setEmergency(e.target.value)}/>
            </div>
            <div className="form-group">
              <label>Emegency Mobile</label>
              <input type="text" value={emergencyMobile} maxLength="15" name="emergency_mobile" className="form-control" placeholder="Enter Emegency Mobile" onChange={(e) => setEmergencyMobile(e.target.value)}/>
            </div>
            <div className="form-group">
              <label>Personal Email</label>
              <input type="text" value={personalEmail} maxLength="50" name="personal_email" className="form-control" placeholder="Enter Personal Email" onChange={(e) => setPersonalEmail(e.target.value)}/>
            </div>
            <div className="form-group">
              <label>Personal Mobile</label>
              <input type="text" value={personalMobile} maxLength="50" name="personal_mobile" className="form-control" placeholder="Enter Personal Mobile" onChange={(e) => setPersonalMobile(e.target.value)}/>
            </div>
            <div className="form-group">
              <label>Marital Status</label>
              <select name="marital_status" className="form-control" value={maritalStatus} onChange={(e) => setMaritalStatus(e.target.value)}>
                <option>--Select--</option>
                <option value="Married">Married</option>
                <option value="Single">Single</option>
                <option value="Divorced">Divorced</option>
                <option value="Widow">Widow</option>
              </select>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <hr/>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <label>Last Logged-In</label>
              <input type="text" disabled={true} value={props.user.last_logged_in} className="form-control"/>
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <label>Updated</label>
              <input type="text" disabled={true} value={props.user.updation_date} className="form-control"/>
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <label>Created</label>
              <input type="text" disabled={true} value={props.user.creation_date} className="form-control"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdditionalProfile;