import React, {useState, useEffect, useRef} from 'react';
import axios from 'axios';
import '../config.js';

import { theme, createFormData } from '../services/helper.js';
import { alertService } from '../services/alert.service.js';
import Progress from '../components/progress.js';

function UserBasicInfo(props){
  const [loading, setLoading] = useState(false);
  const [address, setAddress] = useState(props.user.address);
  const [gender, setGender] = useState(props.user.gender);
  const [status, setStatus] = useState(props.user.status);
  const [role, setRole] = useState(props.user.role);
  const [supervisor, setSupervisor] = useState(props.user.supervisor);
  const [name, setName] = useState(props.user.name);
  const [email, setEmail] = useState(props.user.email);
  const [mobile, setMobile] = useState(props.user.mobile);
  const [supervisors, setSupervisors] = useState([]);
  const [searchUsers, setSearchUsers] = useState([]);

  useEffect(()=>{
    fetchSupervisors();
  }, []);
  
  const handleSupervisorChange = (event) => {
    const target = event.target;
    const value = target.value;
    let searchedUsers = [];
    let counter = 0;
    for(let i = 0;i < supervisors.length;i++){
      if(value.trim() != "" && supervisors[i].active && supervisors[i].name.indexOf(value) >= 0){
        searchedUsers.push(supervisors[i]);
        counter++;
      }
      if(counter == 5){
        break;
      }
    }
    setSearchUsers(searchedUsers);
  }
  
  const handleSubmit = (event) => {
    event.preventDefault();
    if(name && email && mobile && role > 0){
      setLoading(true);
      axios.post(global.config.api_server+'user',createFormData({
        name: name,
        email: email,
        mobile: mobile,
        address: address,
        role: role,
        gender: gender,
        status: status,
        supervisor: supervisor,
        token: localStorage.getItem("token"),
        user_id: props.id,
        task: 'basic'
      })).then(res => {
        setLoading(false);
        if(res.status === 200 && res.data.error === undefined){
          alertService.success('User updated');
        }else{
          alertService.error(res.data.error);
        }
      }).catch(err => {
        alertService.error(err);
        setLoading(false);
      });
    }else{
      alertService.error('Some fields are required');
    }
  }
  
  const fetchSupervisors = () => {
    axios.get(global.config.api_server+'users?token='+localStorage.getItem("token")+'&role=supervisor')
    .then(res => {
      if(res.status === 200){
        const users = res.data.users;
        if(users === null || users === undefined){
          alertService.error("Error fetching users");
        }else{
          setSupervisors(users);
        }
      }
    }).catch(err => {
      alertService.error(err);
    });
  }

  let roleSelection;
  if(parseInt(localStorage.getItem("role")) >= 5){
    roleSelection = <option value="5">Admin</option>;
  }
  const statuses = global.config.status.split(",");
  return(
    <div className="card shadow mb-4">
      <div className="card-header py-3">            
        <button onClick={(e) => handleSubmit(e)} className={'btn btn-icon-split float-right btn-'+theme()}>
          <span className="icon text-white-50">
            <i className="fas fa-save"></i>
          </span>
          <span className="text">Save</span>
        </button>
        <h6 className={'mt-2 font-weight-bold text-'+theme()}>User - Basic Information</h6>
      </div>
      <div className="card-body">
        <div className="row">
          {loading === true ? (
            <Progress label='Saving' small={true}/>
          ):null}
          <div className="col-md-6">
            <div className="form-group">
              <label>Name *</label>
              <input type="text" value={name} maxLength="50" name="name" className="form-control" id="name" placeholder="Enter Name" onChange={(e) => setName(e.target.value)}/>
            </div>
            <div className="form-group">
              <label>Email *</label>
              <input type="email" value={email} maxLength="50" name="email" className="form-control" id="email" placeholder="Enter Email" onChange={(e) => setEmail(e.target.value)}/>
            </div>
            <div className="form-group">
              <label>Mobile *</label>
              <input type="text" value={mobile} maxLength="15" name="mobile" className="form-control" id="mobile" placeholder="Enter Mobile" onChange={(e) => setMobile(e.target.value)}/>
            </div>
            <div className="form-group">
              <label>Gender</label>
              <select name="gender" className="form-control" value={gender} id="gender" onChange={(e) => setGender(e.target.value)}>
                <option>--Select--</option>
                <option value="1">Male</option>
                <option value="0">Female</option>
              </select>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label>Status</label>
              <select name="active" className="form-control" value={status} id="status" onChange={(e) => setStatus(e.target.value)}>
                {statuses.map(d =>
                    <option value={d} key={d}>{d}</option>
                )}
              </select>
            </div>
            <div className="form-group">
              <label>Role *</label>
              <select name="role" className="form-control" value={role} id="role" onChange={(e) => setRole(e.target.value)}>
                <option value="0">None</option>
                <option value="1">Client</option>
                <option value="2">Trainee</option>
                <option value="3">Executive</option>
                <option value="4">Manager</option>
                {roleSelection}
              </select>
            </div>
            <div className="form-group">
              <label>Address</label>
              <textarea value={address} maxLength="200" name="address" className="form-control" id="address" placeholder="Enter Address" onChange={(e) => setAddress(e.target.value)}/>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <hr/>
            <div className="form-group">
              <label><strong>Supervisor</strong></label>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <input type="text" disabled={true} value={props.user.supervisor_name} className="form-control"/>
              <hr/>
              <label>Set New Supervisor</label>
              <input type="text" maxLength="15" className="form-control" placeholder="Search Supervisor" onChange={(e) => handleSupervisorChange(e)}/>
            </div>
          </div>
          <div className="col-md-6">
            {searchUsers.map(searchUser =>
              <div className='form-group'key={searchUser.user_id}>
                <input className='mr-2' type="radio" value={searchUser.user_id} maxLength="50" name="supervisor" id="supervisor" onChange={(e) => setSupervisor(e.target.value)}/>
                <label>{searchUser.name} ({searchUser.email})</label>
              </div>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <hr/>
            <div className="form-group">
              <label><strong>Stats</strong></label>
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <label>Last Logged-In</label>
              <input type="text" disabled={true} value={props.user.last_logged_in} className="form-control"/>
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <label>Updated</label>
              <input type="text" disabled={true} value={props.user.updation_date} className="form-control"/>
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <label>Created</label>
              <input type="text" disabled={true} value={props.user.creation_date} className="form-control"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UserBasicInfo;