import React, {Component} from 'react';

export class Paginator extends Component {

  constructor(props) {
    super(props);
    this.itemCount = 0;
    this.pageCount = 0;
    this.itemCount = props.itemCount;
    this.pageCount = this.calculatePageCount();

    this.state = {
      currentPage: 1,
    };
  }

  componentDidUpdate(prevProps) {
    const { itemCount } = this.props;

    if (prevProps.itemCount !== itemCount) {
      this.itemCount = itemCount;
      this.pageCount = this.calculatePageCount();
      this.setState({ currentPage: 1 });
    }
  }

  has_next() {
    return this.state.currentPage < this.pageCount;
  }

  has_previous() {
    return this.state.currentPage > 1;
  }

  current() {
    return this.state.currentPage;
  }

  num_pages() {
    return this.pageCount;
  }

  render() {
    const previous_page_number = this.state.currentPage - 1;
    const next_page_number = this.state.currentPage + 1;
    let previous_status = "";
    if(this.state.currentPage <= 1){
      previous_status = "disabled";
    }
    let next_status = "";
    if(this.state.currentPage >= this.pageCount){
      next_status = "disabled";
    }
    return this.pageCount > 1 ? (
      <div className="row mb-3">
        <div className="col-sm-12 col-md-4">
          <div className="dataTables_info" id="dataTable_info" role="status" aria-live="polite">
            Showing {this.props.filterTotal} of {this.props.grandTotal} entries
          </div>
        </div>
        <div className="col-sm-12 col-md-8">
            <div className={'dataTables_paginate float-right'}>
              <ul class="pagination">
                <li className={'paginate_button page-item previous '+previous_status}><a href="#" onClick={(event: MouseEvent) => this.handleClick(event, previous_page_number)} class="page-link">Previous</a></li>
            {this.pages()}
                <li className={'paginate_button page-item next '+next_status}><a href="#" onClick={(event: MouseEvent) => this.handleClick(event, next_page_number)} class="page-link">Next</a></li>
              </ul>
          </div>
        </div>
      </div>
    ) : (
      <div className="row mb-3">
        <div className="col-sm-12 col-md-4">
          <div className="dataTables_info" id="dataTable_info" role="status" aria-live="polite">
            Showing {this.props.filterTotal} of {this.props.grandTotal} entries
          </div>
        </div>
      </div> 
    );
  }

  handleClick = (event: MouseEvent, page: number): void => {
    const { onReady, itemsPerPage, onClick } = this.props;

    event.preventDefault();

    const index_start = itemsPerPage * (page - 1);
    const index_end =
      itemsPerPage > 0 // 0 = show all entries
        ? itemsPerPage - 1
        : this.itemCount - 1;

    onClick(index_start, index_start + index_end);

    this.setState({ currentPage: page }, () => {
      if (onReady) {
        onReady();
      }
    });
  };

  pages() {
    const pages = [];

    const { currentPage } = this.state;
    const { showPrevPages, showNextPages } = this.props;

    const morePagesIndicator = (
      <li className="paginate_button page-item">
        <a
          href="#"
            className="page-link"
          onClick={(event: MouseEvent) => event.preventDefault()}
        >
          ...
        </a>
      </li>
    );

    const pageLink = (pageNum: number, isCurrent?: string) => {
      return (
        <li className={'paginate_button page-item '+isCurrent}>
          <a
            href="#"
            className='page-link'
            onClick={(event: MouseEvent) => this.handleClick(event, pageNum)}
          >
            {pageNum}
          </a>
        </li>
      );
    };

    const startAt =
      currentPage > 1 + showPrevPages ? currentPage - showPrevPages : 1;

    const endAt =
      currentPage < this.pageCount - showNextPages
        ? currentPage + showNextPages
        : this.pageCount;

    if (startAt > 1) {
      pages.push(pageLink(1));
      pages.push(morePagesIndicator);
    }

    for (let j = startAt; j <= endAt; j++) {
      pages.push(pageLink(j, j === currentPage ? 'active' : ''));
    }

    if (endAt < this.pageCount) {
      pages.push(morePagesIndicator);
      pages.push(pageLink(this.pageCount));
    }

    return pages;
  }

  calculatePageCount = (): number => {
    const { itemsPerPage } = this.props;
    return itemsPerPage > 0 ? Math.ceil(this.itemCount / itemsPerPage) : 1;
  };
}
