import React, {useState, useEffect, useRef} from 'react';
import { useParams } from 'react-router';
import {Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../config.js';

import { theme, formData } from '../services/helper.js';
import { alertService } from '../services/alert.service.js';
import BannerInfo from '../components/bannerInfo.js';
import Progress from '../components/progress.js';
import SingleDocument from '../components/singleDocument.js';


function Banner(props) {
  const { id } = useParams();
  const [banner, setBanner] = useState({});
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(()=>{
    axios.get(global.config.api_server+'banner?id='+id+'&token='+localStorage.getItem("token")) 
    .then(res => {
      if(res.status === 200 && res.data.status === "success"){
        const currentBanner = res.data.banner;
        if(currentBanner === null || currentBanner === undefined){
          navigate("/banners");
        }else{
          setBanner(currentBanner);
          setLoading(false);
        }
      }else{
        alertService.error(res.data.error);
      }
    }).catch(err => {
      alertService.error(err);
      navigate("/banners");
    });
  }, []);
  
  const handleDelete = (event) => {
    event.preventDefault();
    if(id){
      axios.get(global.config.api_server+'banner?&id='+id+'&token='+localStorage.getItem("token")+'&task=delete').then(res => {
        if(res.status === 200 && res.data.status === "success"){
          alertService.success('Banner deleted');
          navigate("/banner");
        }else{
          alertService.error(res.data.error);
        }
      }).catch(err => {
        alertService.error(err);
      });
    }else{
      alertService.error('Banner ID not found');
    }
  }
  
  let deleteButton = "";
  if(parseInt(localStorage.getItem("role")) >= 5){
    deleteButton = <button className={'btn btn-icon-split float-right btn-'+theme()} data-toggle="modal" data-target="#deleteModal">
                    <span className="icon text-white-50">
                      <i className="fas fa-trash"></i>
                    </span>
                    <span className="text">Delete</span>
                  </button>;
  }

  if(loading){
    return <Progress/>;
  }else{
    return(
      <div className="container-fluid">
        <div className="mb-3">
          {deleteButton}
          <Link to={"/banners"} className="btn btn-light btn-icon-split mr-2">
            <span className="icon text-white-50">
              <i className="fas fa-arrow-left"></i>
            </span>
            <span className="text">Back</span>
          </Link>
        </div>
        
        <BannerInfo id={id} banner={banner}/>

        <SingleDocument id={id} item={banner} type={'banner'}/>
        
        <div className="modal fade" id="deleteModal" tabIndex="-1" role="dialog" aria-labelledby="delete-popup" aria-hidden="true">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="delete-popup">Confirm deletion?</h5>
                <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">Are you sure, you want to delete this banner?</div>
              <div className="modal-footer">
                <button className="btn btn-default" type="button" data-dismiss="modal">Cancel</button>
                <button className={'btn ml-3 btn-'+theme()} onClick={handleDelete} data-dismiss="modal">Delete</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Banner;