import React, {useState, useEffect, useRef} from 'react';
import { useParams } from 'react-router';
import axios from 'axios';
import '../config.js';

import { theme, createFormData } from '../services/helper.js';
import { alertService } from '../services/alert.service.js';
import UserList from '../components/userList.js';
import Progress from '../components/progress.js';
import { Paginator } from '../components/paginator.js';

function Users() {
  const { subnav } = useParams();
  const [search, setSearch] = useState('');
  const [selectedRole, setSelectedRole] = useState(0);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [users, setUsers] = useState([]);
  const [offset, setOffset] = useState(0);
  const [loading, setLoading] = useState(true);
  
  let searchForm = useRef();
  let createForm = useRef();
  let formData = {};

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    formData[name] = value;
  }
  
  const handleSearch = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    let currentSearch = search;
    let currentSelectedRole = selectedRole;
    if(name == "search"){
      setSearch(value);
      currentSearch = value;
    }else if (name == "selection"){
      setSelectedRole(value);
      currentSelectedRole = value;
    }
    const filtered = [];
    for(let i = 0;i<users.length;i++){
      if((currentSearch === undefined || currentSearch.trim() === '' || users[i].name.indexOf(currentSearch) >= 0 || users[i].mobile.indexOf(currentSearch) >= 0 || users[i].email.indexOf(currentSearch) >= 0) && (currentSelectedRole == 0 || users[i].role == currentSelectedRole)){
        filtered.push(users[i]);
      }
    }
    setFilteredUsers(filtered);
  }
  
  const resetSearch = (event) => {
    searchForm.current.reset();
    setSearch('');
    setSelectedRole(0);
    setFilteredUsers(users);
  }
  
  const handleSubmit = (event) => {
    event.preventDefault();
    if(formData.name && formData.email && formData.mobile && formData.role != 0){
      axios.post(global.config.api_server+'user',createFormData({
        name: formData.name,
        email: formData.email,
        mobile: formData.mobile,
        role: formData.role,
        task:"create",
        token: localStorage.getItem("token")
      })).then(res => {
        if(res.status === 200 && res.data.status === "success"){
          alertService.success('New user created');
          setTimeout(function(){
            window.location.reload();
          }, 1000);
        }else{
          alertService.error(res.data.error);
        }
      }).catch(err => {
        alertService.error(err);
      });
    }else{
      alertService.error('All the fields are required');
    }
  }
  
  useEffect(()=>{
    let uri = global.config.api_server+'users?token='+localStorage.getItem("token");
    if(subnav == 'client'){
      uri += "&task=client";
    }
    axios.get(uri)
    .then(res => {
      if(res.status === 200 && res.data.status === "success"){
        const temp = res.data.users;
        setUsers(temp)
        setFilteredUsers(temp);
        setLoading(false);
      }
    }).catch(err => {
      alertService.error(err);
    });
  }, [subnav]);
  
  const onPageChange = (index_start) => {
    setOffset(index_start);
  }

  let createButton;
  let roleSelection;
  if(parseInt(localStorage.getItem("role")) >= 4 && subnav != 'client'){
    createButton =  <a href="#" className={'btn btn-icon-split float-right btn-'+theme()} data-toggle="modal" data-target="#createModal">
                      <span className="icon text-white-50">
                        <i className="fas fa-user"></i>
                      </span>
                      <span className="text">Create New User</span>
                    </a>;
  }
  if(parseInt(localStorage.getItem("role")) >= 5){
    roleSelection = <option value="5">Admin</option>;
  }
  let total = 0;
  let counter = 0;
  let offsetCounter = 0;
  const paginated_users = [];
  for (const u of filteredUsers) {
    total++;
    offsetCounter++;
    if (offsetCounter > offset && counter < global.config.items_per_page){
      counter++;
      paginated_users.push(u);
    }
  }
  if(loading){
    return <Progress/>;
  }else{
    return(
      <div className="container-fluid">
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            {createButton}
            <form ref={searchForm} className="form-inline">
              <h6 className={'form-group mr-3 font-weight-bold text-'+theme()}>Users</h6>
              <div className="form-group mr-3">
                <input type="search" name="search" className="form-control" placeholder="Search" onChange={handleSearch}/>
              </div>
              {subnav != 'client' &&
              <div className="form-group mr-3">
                <select name="selection" className="form-control" onChange={handleSearch}>
                  <option value="0">All</option>
                  <option value="2">Intern</option>
                  <option value="3">Executive</option>
                  <option value="4">Manager</option>
                  {roleSelection}
                </select>
              </div>
              }
              {(selectedRole > 0 || search) &&
              <div className="form-group mr-3">
                <a href="#" className="btn btn-default btn-icon-split" onClick={resetSearch}>
                  <span className="icon">
                    <i className="fas fa-times"></i>
                  </span>
                </a>
              </div>
              }
            </form>
          </div>
          <div className="card-body">
            <div className="table-responsive">
              <div className="row">
                <div className="col-sm-12">
                  <table className="table table-bordered" width="100%">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Mobile</th>
                        <th>Role</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {paginated_users.map(user=>
                        <UserList key={user.user_id} info={user}/>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              <Paginator
                itemCount={total}
                itemsPerPage={global.config.items_per_page}
                showNextPages={2}
                showPrevPages={2}
                filterTotal={filteredUsers.length}
                grandTotal={users.length}
                onClick={onPageChange.bind(this)}
              />
            </div>
          </div>
        </div>
        <div className="modal fade" id="createModal" tabIndex="-1" role="dialog" aria-labelledby="create-popup" aria-hidden="true">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="create-popup">Create New User</h5>
                <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <form ref={createForm}>
                <div className="modal-body">
                  <div className="form-group">
                    <label>Name</label>
                    <input type="text" maxLength="50" name="name" className="form-control" id="name" placeholder="Enter Name" onChange={handleInputChange}/>
                  </div>
                  <div className="form-group">
                    <label>Email</label>
                    <input type="email" maxLength="50" name="email" className="form-control" id="email" placeholder="Enter Email" onChange={handleInputChange}/>
                  </div>
                  <div className="form-group">
                    <label>Mobile</label>
                    <input type="text" maxLength="15" name="mobile" className="form-control" id="mobile" placeholder="Enter Mobile" onChange={handleInputChange}/>
                  </div>
                  <div className="form-group">
                    <label>Role</label>
                    <select name="role" className="form-control" id="role" onChange={handleInputChange}>
                      <option value="0">-Select-</option>
                      <option value="2">Intern</option>
                      <option value="3">Executive</option>
                      <option value="4">Manager</option>
                      {roleSelection}
                    </select>
                  </div>
                </div>
                <div className="modal-footer">
                  <button className="btn btn-default" type="reset" data-dismiss="modal">Close</button>
                  <button className={'btn btn-'+theme()} type="button" onClick={handleSubmit}>Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Users;