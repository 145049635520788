import React, {useState, useEffect, useRef} from 'react';
import axios from 'axios';
import '../config.js';
import { theme, createFormData } from '../services/helper.js';
import { alertService } from '../services/alert.service.js';
import Progress from '../components/progress.js';

function Seo(props) {
  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState(props.item.seo_description);
  const [title, setTitle] = useState(props.item.seo_title);
  
  const handleSubmit = (event) => {
    event.preventDefault();
    if(title && props.id){
      setLoading(true);
      axios.post(global.config.api_server+'seo',createFormData({
        title: title,
        description: description,
        id: props.id,
        type: props.type,
        token: localStorage.getItem("token")
      })).then(res => {
        setLoading(false);
        if(res.status === 200 && res.data.status === "success"){
          alertService.success('SEO updated');
        }else{
          alertService.error(res.data.error);
        }
      }).catch(err => {
        alertService.error(err);
        setLoading(false);
      });
    }else{
      alertService.error('Some fields are required');
    }
  }

  return(
    <div className="card shadow mb-4">
      <div className="card-header py-3">            
        <button onClick={handleSubmit} className={'btn btn-icon-split float-right btn-'+theme()}>
          <span className="icon text-white-50">
            <i className="fas fa-save"></i>
          </span>
          <span className="text">Save</span>
        </button>
        <h6 className={'mt-2 font-weight-bold text-'+theme()}>SEO</h6>
      </div>
      <div className="card-body">
        <div className="row justify-content-center">
          {loading === true ? (
            <Progress label='Saving' small={true}/>
          ):null}
          <div className="col-md-6">
            <div className="form-group">
              <label>Page Title *</label>
              <input type="text" value={title} maxLength="50" name="title" className="form-control" id="title" placeholder="Enter Page Title" onChange={(e) => setTitle(e.target.value)}/>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label>Meta Description</label>
              <textarea value={description} maxLength="200" name="description" className="form-control" id="description" placeholder="Enter Meta Description" onChange={(e) => setDescription(e.target.value)}></textarea>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Seo;