import React, {useState, useEffect, useRef} from 'react';
import axios from 'axios';
import '../config.js';

import { theme, createFormData } from '../services/helper.js';
import { alertService } from '../services/alert.service.js';
import Progress from '../components/progress.js';
import RichTextEditor from '../components/rte.js';

function CategoryInfo(props) {
  const [loading, setLoading] = useState(false);
  const [label, setLabel] = useState(props.category.label);
  const [placement, setPlacement] = useState(props.category.placement);
  const [description, setDescription] = useState(props.category.description);
  const [active, setActive] = useState(props.category.active);
  
  const handleSubmit = (event) => {
    event.preventDefault();
    if(label && props.id){
      setLoading(true);
      axios.post(global.config.api_server+'category',createFormData({
        label: label,
        active: active,
        id: props.id,
        task: 'edit',
        placement: placement,
        description: description,
        token: localStorage.getItem("token")
      })).then(res => {
        setLoading(false);
        if(res.status === 200 && res.data.status === "success"){
          alertService.success('Category updated');
        }else{
          alertService.error(res.data.error);
        }
      }).catch(err => {
        alertService.error(err);
        setLoading(false);
      });
    }else{
      alertService.error('Some fields are required');
    }
  }

  const placements = global.config.placements.split(",");
  return(
    <div className="card shadow mb-4">
      <div className="card-header py-3">            
        <button onClick={handleSubmit} className={'btn btn-icon-split float-right btn-'+theme()}>
          <span className="icon text-white-50">
            <i className="fas fa-save"></i>
          </span>
          <span className="text">Save</span>
        </button>
        <h6 className={'mt-2 font-weight-bold text-'+theme()}>Category Information</h6>
      </div>
      <div className="card-body">
        <div className="row justify-content-center">
          {loading === true ? (
            <Progress label='Saving' small={true}/>
          ):null}
          <div className="col-md-6">
            <div className="form-group">
              <label>Identifier</label>
              <input type="text" value={props.category.identifier} maxLength="50" name="identifier" className="form-control disabled" id="name" placeholder="Enter Identfier" readOnly={true}/>
            </div>
            <div className="form-group">
              <label>Label *</label>
              <input type="text" value={label} maxLength="50" name="label" className="form-control" id="label" placeholder="Enter Label" onChange={(e) => setLabel(e.target.value)}/>
            </div>
            <div className="form-group">
              <label>Placements</label>
              <select name="placments" value={placement} className="form-control" onChange={(e) => setPlacement(e.target.value)}>
              {placements.map(d =>
                <option value={d} key={d}>{d}</option>
              )}
              </select>
            </div>
            <div className="form-group">
              <label>Status</label>
              <select name="active" className="form-control" value={active} id="active" onChange={(e) => setActive(e.target.value)}>
                <option value="0">In-Active</option>
                <option value="1">Active</option>
              </select>
            </div>
          </div>
          <div className="col-md-6">
            <RichTextEditor id={'description-'+props.category.category_id} label={'Description'} value={props.category.description} handleSubmit={(e)=>setDescription(e)}/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CategoryInfo;